import React, { useState } from 'react';
import { Container} from 'semantic-ui-react'
import ConfirmModal from "../shared/ConfirmModal";
import auth0Client from "../../auth/Auth";

function IframeActivity(props) {
  const user = auth0Client.getProfile();
  const [notLoggedInModalOpen, setNotLoggedInModalOpen] = useState(!(user || {}).sub);

  const { location } = props;
  const localDomain = 'http://localhost:8080';
  const prodDomain = 'https://v1.grokchemistry.com';
  const domain = process.env.NODE_ENV === 'development' ? localDomain : prodDomain;
  let url = '';

  const userId = (user || {}).sub;
  let query = '?nosidebar=true';
  if (userId) {
    query = `?nosidebar=true&userid=${userId}`;
  }


  if (location.pathname === '/puzzles') {
    url = `${domain}/ionic-bonding-puzzles${query}`;
  } else if (location.pathname === '/bohr-models') {
    url = `${domain}/bohr${query}`;
  } else if (location.pathname === '/pt-groups') {
    url = `${domain}/pt-classify-groups${query}`;
  } else if (location.pathname === '/ionic-bonding-formulas') {
    url = `${domain}/ionic-bonding${query}`;
  } else if (location.pathname === '/lewis-dot-structures') {
    url = `${domain}/lewis-dot-structures${query}`;
  }

  return (
    <Container text className="pageWrapper">
      <iframe
        title="Activity"
        width={675}
        height={650}
        src={url}
      />

      <ConfirmModal
        open={notLoggedInModalOpen}
        title="Not Logged In"
        confirmButtonLabel="Ok"
        confirmText="You are not logged in. You can do the practice activities but your results will not be saved."
        confirmButtonColor="green"
        onConfirm={() => setNotLoggedInModalOpen(false)}
        onCancel={() => setNotLoggedInModalOpen(false)}
      />
    </Container>
  );
}

export default IframeActivity;
