import React from 'react';
import { Button, Modal, Input, Select } from "semantic-ui-react";
import { withFormik } from 'formik';

class ConfirmModal extends React.Component {
  render() {
    const {
      closeModal,
      open,
      errors,
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      touched,
      setFieldValue,
      isSubmitting
    } = this.props;

    return (
      <Modal
        className="editProfileModalForm"
        open={open}
      >
        <Modal.Header>Edit Profile</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>We just need a couple of things from you...</p>
          </Modal.Description>

          <form onSubmit={handleSubmit}>
            <Input
              className="firstNameInput"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              name="firstName"
              placeholder="First Name"
              error={errors.firstName && touched.firstName}
            />

            <Input
              className="lastNameInput"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              name="lastName"
              placeholder="Last Name"
              error={errors.lastName && touched.lastName}
            />

            <Select
              className="roleSelectInput"
              placeholder='Select your role'
              onChange={(e, { name, value }) => setFieldValue(name, value)}
              name="role"
              options={[{value: 'student', text: 'Student'}, { value: 'teacher', text: 'Teacher' }]}
              value={values.role}
            />
          </form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            type="button"
            color="green"
            onClick={handleSubmit}
            disabled={isSubmitting || Object.keys(errors).length > 0}
          >
            {isSubmitting ? 'Updating...' : 'Save Changes'}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default withFormik({
  mapPropsToValues: (props) => {
    const { userProfile } = props;
    return {
      firstName: userProfile.firstName || '',
      lastName: userProfile.lastName || '',
      role: userProfile.role || 'student',
    }
  },

  // Custom sync validation
  validate: values => {
    const errors = {};

    if (!values.lastName) {
      errors.lastName = 'Required';
    }

    if (!values.firstName) {
      errors.firstName = 'Required';
    }

    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    const { updateUserProfile, auth0UserProfile, closeModal, refetchUserProfile } = props;
    setSubmitting(true);
    updateUserProfile({
      variables: {
        userId: auth0UserProfile.sub,
        firstName: values.firstName,
        lastName: values.lastName,
        role: values.role
      }}).then(() => {
      refetchUserProfile();
      closeModal();
    });
  },

  displayName: 'BasicForm',
})(ConfirmModal);
