export const hydrogen = {
  symbol: 'H',
  valence: 1,
  numberOfBonds: [1],
  bondingElectrons: [2],
};

export const carbon = {
  symbol: 'C',
  valence: 4,
  numberOfBonds: [4],
  bondingElectrons: [8]
};

export const nitrogen = {
  symbol: 'N',
  valence: 5,
  numberOfBonds: [3],
  bondingElectrons: [8]
};

export const oxygen = {
  symbol: 'O',
  valence: 6,
  numberOfBonds: [2],
  bondingElectrons: [8]
};

export const fluorine = {
  symbol: 'F',
  valence: 7,
  numberOfBonds: [1],
  bondingElectrons: [8]
};

export const phosphorus = {
  symbol: 'P',
  valence: 5,
  numberOfBonds: [3],
  bondingElectrons: [8]
};

export const sulfur = {
  symbol: 'S',
  valence: 6,
  numberOfBonds: [2],
  bondingElectrons: [8]
};

export const chlorine = {
  symbol: 'Cl',
  valence: 7,
  numberOfBonds: [1],
  bondingElectrons: [8]
};

export const bromine = {
  symbol: 'Br',
  valence: 7,
  numberOfBonds: [1],
  bondingElectrons: [8]
};

export const iodine = {
  symbol: 'I',
  valence: 7,
  numberOfBonds: [1],
  bondingElectrons: [8]
};

export default {
  hydrogen,
  carbon,
  nitrogen,
  oxygen,
  fluorine,
  phosphorus,
  sulfur,
  chlorine,
  bromine,
  iodine,
}
