import React from 'react';
import { Button, Modal } from "semantic-ui-react";

class ConfirmModal extends React.Component {
  render() {
    const {
      title,
      confirmButtonLabel='Confirm',
      confirmText,
      confirmButtonColor='green',
      onConfirm,
      open,
    } = this.props;
    return (
      <Modal
        open={open}
      >
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>{confirmText}</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color={confirmButtonColor}
            onClick={onConfirm}
          >
            {confirmButtonLabel}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default ConfirmModal;
