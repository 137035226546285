const problemTypes = {
  covalentBondingStructures: {
    id: 'covalentBondingStructures',
    name: 'Covalent Bonding',
  },
  lewisDotStructuresOfAtoms: {
    id: 'lewisDotStructuresOfAtoms',
    name: 'Lewis Dot Structures of Atoms',
  },
  ionicFormulas: {
    id: 'ionicFormulas',
    name: 'Ionic Bonding Formulas',
  },
  ionicBondingPuzzles: {
    id: 'ionicBondingPuzzles',
    name: 'Ionic Bonding Puzzles',
  },
  periodicTableSorting: {
    id: 'periodicTableSorting',
    name: 'Periodic Table Sort',
  },
  bohrModels: {
    id: 'bohrModels',
    name: 'Bohr Models of the Atom'
  },
};

export default problemTypes;
