import React from "react";
import { Route, Switch } from "react-router-dom"; //BrowserRouter ?
import Home from '../home/Home';
import NotFound from '../NotFound'
import ComponentWithGQLQuery from '../testComponents/ComponentWithGQLQuery';
import ComponentWithGQLCompose from '../testComponents/ComponentWithGQLCompose';
import IframeActivity from '../iframeactivities/IframeActivity';
import CovalentStructures from '../covalentBonding/CovalentStructures';
import LewisDotStructures from '../lewisDotStructures/LewisDotStructures';
import Login from '../login/Login';
import CompletedProblems from '../completedProblems/CompletedProblems';
import ProblemsSharedWithMe from '../sharedWithMe/SharedWithMe';
import routeConstants from './constants';
import Profile from '../profile/Profile';
import PrivateRoute from '../routes/PrivateRoute';
import Callback from '../login/Callback';
import Auth from '../../auth/Auth';

class Routes extends React.Component {
    async componentDidMount() {
        if (window.location.pathname === '/callback') return;
        try {
            await Auth.silentAuth();
            this.forceUpdate();
        } catch (err) {
            if (err.error === 'login_required') return;
            console.log(err.error);
        }
    }

    render() {
        return (
          <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/test1" component={ComponentWithGQLQuery} />
              <Route path="/test2" component={ComponentWithGQLCompose} />
              <Route exact path='/callback' component={Callback}/>
              <Route path={routeConstants.completedProblems} component={CompletedProblems} />
              <Route path={routeConstants.problemsSharedWithMe} component={ProblemsSharedWithMe} />
              <Route path={routeConstants.atomicStructure.bohrModels} component={IframeActivity} />
              <Route path={routeConstants.periodicTable.sortingIntoGroups} component={IframeActivity} />
              <Route path={routeConstants.ionicBonding.ionicBondingPuzzles} component={IframeActivity} />
              <Route path={routeConstants.ionicBonding.ionicBondingFormulas} component={IframeActivity} />
              <Route path={routeConstants.covalentBonding.lewisDotStructures} component={LewisDotStructures} />
              <Route path={routeConstants.covalentBonding.covalentBonding} component={CovalentStructures} />
              <Route path={routeConstants.login} component={Login} />
              <Route path={routeConstants.privacyPolicy} component={Login} />
              <PrivateRoute path={routeConstants.profile} component={Profile} />
            <Route component={NotFound} />
          </Switch>
        );
    }
}

export default Routes;
