import React, { useState } from "react";
import {Mutation, Query} from "react-apollo";
import { Loader, Button } from 'semantic-ui-react'
import auth0Client from '../../auth/Auth';
import EditProfileModal from './EditProfileModal';
import { GET_USER_PROFILE, UPDATE_USER_PROFILE } from "../../gql/queries/queries";


const Profile = () => {
  const [editProfileModalOpen, hideShowModal] = useState(false);

  const isAuthenticated = auth0Client.isAuthenticated();
  const user = auth0Client.getProfile();

  if (!isAuthenticated) {
    return <div>Must be logged in.</div>;
  }

  if (!user) {
    return (
      <div
        className="pageWrapper"
      >
        <Loader
          active
          size="massive"
        />
      </div>
    );
  }

  return (
      <Query
        query={GET_USER_PROFILE}
        variables={{ userId: user.sub }}
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data, refetch }) => {

          if (loading) {
            return (
              <div
                className="pageWrapper"
              >
                <Loader active size="massive" />
              </div>
            )
          }

          if (data) {
            const { getUserProfile: userProfile } = data;
            return (
              <div className="conProfile">
                <div className="grokCard">
                  <Button
                    className="editProfileBtn"
                    onClick={() => hideShowModal(!editProfileModalOpen)}
                  >
                    Edit Profile
                  </Button>

                  <div>
                    <img src={user.picture} width={50} alt="Profile" />
                    <h2>{userProfile.firstName} {userProfile.lastName}</h2>
                    <h3>{userProfile.role}</h3>
                    <p>{userProfile.email}</p>
                  </div>
                </div>

                <Mutation mutation={UPDATE_USER_PROFILE}>
                  {updateUserProfile =>
                    <EditProfileModal
                      refetchUserProfile={refetch}
                      userProfile={userProfile}
                      auth0UserProfile={user}
                      open={editProfileModalOpen}
                      closeModal={() => hideShowModal(false)}
                      updateUserProfile={updateUserProfile}
                    />
                  }

                </Mutation>

              </div>
            )
          }
        }}
      </Query>
  );
};

export default Profile;
