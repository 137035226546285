import React from 'react';
import { Mutation } from 'react-apollo';
import { Container, Button } from 'semantic-ui-react';
import { Stage, Layer, Text, Circle, Line } from 'react-konva';
import { createRandomId, getDistanceBetweenCoords, getRandomIntInclusive } from '../../utils/utils';
import covalentBondingProblems from './problems';
import { createStringifiedResult } from '../../utils/saveProblem';
import problemTypes from '../../utils/problemTypeConstants';
import { SAVE_PRACTICE_RESULTS_WITH_USER_ID } from '../../gql/queries/queries';
import ConfirmModal from '../shared/ConfirmModal';
import auth0Client from "../../auth/Auth";


class CovalentStructures extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentProblem: {
        elements: [],
      },
      userElements: [],
      userElectrons: [],
      userBonds: [],
      addingItems: false,
      removingItems: false,
      currentBondPoint1: null,
      currentBondPoint2: null,
      mousePosition: null,
      dragging: false,
      answerIsCorrect: false,
      numberOfTimesChecked: 0,
      errors: {},
      workedProblems: []
    }
  }

  componentDidMount() {
    // Problem to solve
    const currentProblemIndex = getRandomIntInclusive(0, (this.props.problems || covalentBondingProblems).length - 1);

    this.setState( {
      currentProblem: (this.props.problems || covalentBondingProblems)[currentProblemIndex],
      userElements: [],
      userElectrons: [],
      workedProblems: [currentProblemIndex],
      notLoggedInModalOpen: !(this.props.user || {}).sub
    });
  }

  addElement(element, location = { x: 20, y: 100 }) {
    const id = createRandomId(5);
    const userElements = this.state.userElements;

    const elementLength = element.symbol.length;

    const xAdjustment = elementLength === 2 ? 66 : 33;
    const yAdjustment = 95 /2;

    const elementToAdd = {
      ...element,
      id,
      x: location.x - xAdjustment,
      y: location.y - yAdjustment,
    };

    userElements.push(elementToAdd);
    this.setState({
      userElements,
      // addingItems: false
    });
  }

  addElectron(location = { x: 30, y: 200 }) {
    const id = createRandomId(5);
    const userElectrons = this.state.userElectrons;

    const electronToAdd = {
      id,
      x: location.x,
      y: location.y,
    };

    userElectrons.push(electronToAdd);
    this.setState({
      userElectrons,
      // addingItems: false
    });
  }

  addBond() {
    const userBonds = this.state.userBonds;
    if (this.state.currentBondPoint1 && this.state.currentBondPoint2) {
      userBonds.push({
        points: [
          this.state.currentBondPoint1[0],
          this.state.currentBondPoint1[1],
          this.state.currentBondPoint2[0],
          this.state.currentBondPoint2[1]
        ],
        stroke: 'black'
      })
    }

    // clear out the drawing of a bond
    this.setState({
      userBonds,
      currentBondPoint1: null,
      currentBondPoint2: null,
      // addingItems: false,
      mousePosition: null
    });

  }

  updateMostRecentBond() {
    const userBonds = this.state.userBonds;
    userBonds.pop();

    if (this.currentBondPoint1 && this.currentBondPoint2) {
      this.addBond();
    }
  }

  removeItem(itemToRemove) {
    const userElements =
      this.state.userElements
        .filter(element =>
          !(element.x === itemToRemove.x && element.y === itemToRemove.y)
        );
    this.setState( { userElements });

    const userElectrons =
      this.state.userElectrons
        .filter(electron =>
          !(electron.x === itemToRemove.x && electron.y === itemToRemove.y)
        );
    this.setState( { userElectrons });
  }

  updateElementPosition(element) {
    const userElements = this.state.userElements.filter(e => e.id !== element.id);
    userElements.push(element);
    this.setState({ userElements, dragging: false });
  }

  updateElectronPosition(electron) {
    const userElectrons = this.state.userElectrons.filter(e => e.id !== electron.id);
    userElectrons.push(electron);
    this.setState({ userElectrons, dragging: false });
  }

  handleItemClick(e) {
  if (this.state.removingItems) {
      this.removeItem(e.target.attrs)
    }
  }

  // Add/remove elements or electrons
  handleClick(e) {
    if (this.state.addingItems && this.state.addingItems === 'electrons') {
      this.addElectron({x: e.evt.offsetX, y: e.evt.offsetY});
    } else if (this.state.addingItems && this.state.addingItems !== 'bonds') {
      const element = this.state.currentProblem.elements.find(element => element.symbol === this.state.addingItems)
      this.addElement(element, {x: e.evt.offsetX, y: e.evt.offsetY })
    }
    // else if (this.state.removingItems) {
    //   this.removeItem(e.target.attrs)
    // }
  }

  // Erase bonds
  // TODO - Consider switching the handle click over to this type...
  handleBondClick(bond) {
    if (this.state.removingItems) {
      const userBonds = this.state.userBonds.filter((b) => {
        return !(
          bond.points[0] === b.points[0] &&
          bond.points[1] === b.points[1] &&
          bond.points[2] === b.points[2] &&
          bond.points[3] === b.points[3]
        )
      });
      this.setState({ userBonds });
    }
  }

  // Start to create a bond by dragging
  handleMouseDown(e) {
    if (this.state.addingItems && this.state.addingItems === 'bonds') {
      if (!this.state.currentBondPoint1) {
        this.setState({currentBondPoint1: [e.evt.offsetX, e.evt.offsetY]});
      } else if (!this.state.currentBondPoint2) {
        this.setState({currentBondPoint2: [e.evt.offsetX, e.evt.offsetY]});
        this.addBond()
      }
    }
  }

  // Add bond on touch screen
  handleTouchStart(e) {
    const location = e.currentTarget.pointerPos;

    if (this.state.addingItems && this.state.addingItems === 'bonds') {
      const updatedState = { currentBondPoint1: [location.x, location.y], currentBondPoint2: null};
      this.setState(() => updatedState)
    }

  }

  handleTouchMove(e) {
    if (this.state.addingItems && this.state.addingItems === 'bonds') {
      const location = e.currentTarget.pointerPos;
      this.setState({ currentBondPoint2: [location.x, location.y]})
      // this.updateMostRecentBond()
    }



  }

  handleTouchEnd(e) {
    if (this.state.addingItems && this.state.addingItems === 'bonds') {
      // this.setState({ currentBondPoint2: [location.x, location.y]})
      // this.updateMostRecentBond()
      this.addBond()
    }
  }



  // Started or Finished creating a bond
  handleMouseUp(e) {
    if (this.state.addingItems && this.state.addingItems === 'bonds') {
      if (!this.state.currentBondPoint1) {
        this.setState({currentBondPoint1: [e.evt.offsetX, e.evt.offsetY]});
      } else if (this.state.currentBondPoint2) {
        this.setState({currentBondPoint2: [e.evt.offsetX, e.evt.offsetY]});
        this.addBond()
      }
    }
  }

  // While creating a new bond track mouse movement
  handleMouseMove(e) {
    if (this.state.addingItems === 'bonds' && this.state.currentBondPoint1) {
      // this.setState({ mousePosition: { x: e.evt.offsetX, y: e.evt.offsetY }})
      this.setState({ currentBondPoint2: [e.evt.offsetX, e.evt.offsetY]});
    }
  }

  handleMove(e) {
    // console.log('move!!!!', e);
  }

  // To allow css styling while dragging
  handleDragStart() {
    this.setState({ dragging: true, addingItems: false, removingItems: false })
  }

  // Toggle action buttons
  handleButtonClick(buttonType, item) {
    const currentlyAddingItems = this.state.addingItems;
    const currentlyRemovingItems = this.state.removingItems;

    // Add Buttons (with toggle on/off)
    if (buttonType === 'elements') {
      const updatedAddingItems = currentlyAddingItems === item.symbol ? false : item.symbol;
      this.setState( { addingItems: updatedAddingItems, removingItems: false })
    } else if (buttonType === 'electrons') {
      const updatedAddingItems = currentlyAddingItems === 'electrons' ? false : 'electrons';
      this.setState( { addingItems: updatedAddingItems, removingItems: false })
    } else if (buttonType === 'bonds') {
      const updatedAddingItems = currentlyAddingItems === 'bonds' ? false : 'bonds';
      this.setState( { addingItems: updatedAddingItems, removingItems: false, points: [] })
    }

    // Remove Button
    if (buttonType === 'remove') {
      this.setState({ addingItems: false, removingItems: !currentlyRemovingItems })
    }
  }

  countAtoms(elementList) {
    const elementsUsed = elementList.reduce((acc, element) => {
      // Check if we have started counting this element yet
      const previousCount = acc.find(el => el.symbol === element.symbol)

      // Start counting if it's new
      if (!previousCount) {
        return [ ...acc, { symbol: element.symbol, count: 1 } ]
      }

      // Increment the count if we have already found it
      const updatedCount = { ...previousCount, count: previousCount.count + 1 };
      return [ ...acc.filter(el => el.symbol !== element.symbol), updatedCount ]
    }, []);
    return elementsUsed;
  }

  checkNumberOfAtomsAreCorrect() {
    const userAtomCount = this.countAtoms(this.state.userElements);

    // Loop through problem and compare to what the user has done
    let numberOfAtomsAreCorrect = true;
    this.state.currentProblem.elements.forEach(element => {
      const userAtom = userAtomCount.find(el => el.symbol === element.symbol);
      if (!userAtom || (userAtom.count !== element.subscript)) {
        numberOfAtomsAreCorrect = false
      }
    });
    return numberOfAtomsAreCorrect;
  }

  checkTotalNumberOfElectronsAreCorrect() {
    // User's Answer
    const totalUserElectrons =
      this.state.userElectrons.length + 2 * this.state.userBonds.length;

    // Problem
    const electronsFromCharge = this.state.currentProblem.charge * -1;
    const electronsFromElements = this.state.currentProblem.elements.reduce((acc, element) => {
      return acc + (element.valence * element.subscript);
    }, 0);

    return totalUserElectrons === (electronsFromCharge + electronsFromElements);
  }

  getCenterOfElement(element) {
    // Get center of atom
    const width = element.symbol.length === 1 ? 66 : 132;
    const height = 95;

    const centerOfElement = {
      x: element.x + (width / 2) ,
      y: element.y + (height  / 2)
    };

    return centerOfElement;
  }

  checkIfElectronBelongsToElement(element, electron) {
    const centerOfElement = this.getCenterOfElement(element);

    const electronDistanceFromCenter = getDistanceBetweenCoords(centerOfElement, electron)
    const thresholdDistance = element.symbol.length === 1 ? 60 : 95; // tried adding 5 to each
    return electronDistanceFromCenter <= thresholdDistance;
  }

  checkIfBondBelongsToElement(element, bond) {
    const centerOfElement = this.getCenterOfElement(element);

    const bondEnd1DistanceFromCenter = getDistanceBetweenCoords(centerOfElement, { x: bond.points[0], y: bond.points[1] });
    const bondEnd2DistanceFromCenter = getDistanceBetweenCoords(centerOfElement, { x: bond.points[2], y: bond.points[3] });
    const thresholdDistance = element.symbol.length === 1 ? 60 : 95;

    // If either end is within threshold, count it
    return (bondEnd1DistanceFromCenter <= thresholdDistance || bondEnd2DistanceFromCenter <= thresholdDistance);
  }

  checkElectronsOnEachAtomAreCorrect() {
    // An electron belongs to an atom if:
    // - width of a single char atom is 66, a two char atom is 132
    // - height seems to be about 95

    const elements = [];
    this.state.userElements.forEach(element => {
      let numberOfElectrons = 0;

      // Add lone electrons
      this.state.userElectrons.forEach(electron => {
        if (this.checkIfElectronBelongsToElement(element, electron)) {
          numberOfElectrons = numberOfElectrons + 1;
        }
      });

      // Add bonding electrons
      this.state.userBonds.forEach(bond => {
        if (this.checkIfBondBelongsToElement(element, bond)) {
          numberOfElectrons = numberOfElectrons + 2;
        }
      });

      elements.push({ ...element, numberOfLoneAndBondingElectrons: numberOfElectrons})
    });

    // Check that they are all correct...
    let electronsAreAllCorrect = elements.length ? true : false;
    elements.forEach(element => {
      const correct = this.state.currentProblem.elements.find(el => el.symbol === element.symbol);
      if (this.props.problemType !== 'lewisDotStructuresOfAtoms' && !correct.bondingElectrons.includes(element.numberOfLoneAndBondingElectrons)) {
        electronsAreAllCorrect = false
      }
    });

    return electronsAreAllCorrect;
  }

  checkBondsOnEachAtomAreCorrect() {
    // An electron belongs to an atom if:
    // - width of a single char atom is 66, a two char atom is 132
    // - height seems to be about 95

    const elements = [];
    this.state.userElements.forEach(element => {
      let numberOfBonds = 0;

      // Add bonding electrons
      this.state.userBonds.forEach(bond => {
        if (this.checkIfBondBelongsToElement(element, bond)) {
          numberOfBonds = numberOfBonds + 1;
        }
      });

      elements.push({ ...element, numberOfBonds })
    });

    // Check that they are all correct...
    let bondsAreAllCorrect = elements.length ? true : false;
    elements.forEach(element => {
      const correct = this.state.currentProblem.elements.find(el => el.symbol === element.symbol);
      if (this.props.problemType !== 'lewisDotStructuresOfAtoms' && !correct.numberOfBonds.includes(element.numberOfBonds)) {
        bondsAreAllCorrect = false
      }
    });

    return bondsAreAllCorrect;
  }

  checkAnswer() {
    // Count total number of each type of atom
    const atomsAreCorrect = this.checkNumberOfAtomsAreCorrect();

    // Count total number of electrons
    const totalElectronsAreCorrect = this.checkTotalNumberOfElectronsAreCorrect();


    // Count electrons around each atom (include lone pairs and bonds)
    const electronsOnEachAtomAreCorrect = this.checkElectronsOnEachAtomAreCorrect();

    // Count bonds on each atom
    const bondsOnEachAtomAreCorrect = this.checkBondsOnEachAtomAreCorrect();

    if (atomsAreCorrect && totalElectronsAreCorrect && electronsOnEachAtomAreCorrect && bondsOnEachAtomAreCorrect) {
      const numberOfTimesChecked = this.state.numberOfTimesChecked;
      this.setState({ answerIsCorrect: true, numberOfTimesChecked: numberOfTimesChecked + 1, });

      // Save this problem to GQL
      const stringifiedResult = createStringifiedResult(
        this.state.currentProblem,
        numberOfTimesChecked + 1,
        true,
        (this.props.problemType || problemTypes.covalentBondingStructures.id),
        (this.props.user || {}).sub,
      );
      // Send Mutation to GQL
      if (this.props.user && this.props.user.sub) {
        this.props.saveProblem({ variables: { userId: (this.props.user || {}).sub, stringifiedResult }})
      } else {
        console.warn('Not logged in');
      }
    } else {
      const numberOfTimesChecked = this.state.numberOfTimesChecked;
      this.setState( {
        answerIsCorrect: false,
        errors: {
          atomsAreCorrect,
          totalElectronsAreCorrect,
          electronsOnEachAtomAreCorrect,
          bondsOnEachAtomAreCorrect,
        },
        numberOfTimesChecked: numberOfTimesChecked + 1,
      });
    }
  }

  advanceToNextProblem() {
    let alreadyWorkedProblems = this.state.workedProblems;

    let currentProblemIndex = null;

    // All the problems have been completed, clear the list of completes if they want to keep going
    if (alreadyWorkedProblems.length >= (this.props.problems || covalentBondingProblems).length) {
      alreadyWorkedProblems = [];
    }

    // Find a new problem
    let safetyFactor = 0;
    do {
      safetyFactor = safetyFactor + 1;
      if (safetyFactor > 500) {
        throw new Error('Error selecting next problem.');
      }
      currentProblemIndex = getRandomIntInclusive(0, (this.props.problems || covalentBondingProblems).length - 1);
    } while (alreadyWorkedProblems.includes(currentProblemIndex));

    alreadyWorkedProblems.push(currentProblemIndex);

    this.setState({
      userElectrons: [],
      userElements: [],
      userBonds: [],
      answerIsCorrect: false,
      numberOfTimesChecked: 0,
      errors: {},
      currentProblem: (this.props.problems || covalentBondingProblems)[currentProblemIndex],
      addingItems: false,
      removingItems: false,
      currentBondPoint1: null,
      currentBondPoint2: null,
      mousePosition: null,
      dragging: false,
      alreadyWorkedProblems,
    })
  }

  renderSymbol(element, i) {
    return (
      <Text
        ref="hellothere"
        key={i}
        x={element.x}
        y={element.y}
        draggable={!this.state.addingItems && !this.state.removingItems}
        fontSize={100}
        fontFamily="Courier"
        fill="black"
        align="center"
        verticalAlign="center"
        text={element.symbol}
        onClick={e => this.handleItemClick(e)}
        onTap={e => this.handleItemClick(e)}
        onDragStart={() => this.handleDragStart()}
        onDragEnd={e => {
          this.updateElementPosition({
            ...element,
            x: e.target.x(),
            y: e.target.y()
          });
        }}
      />
    );
  }

  renderElectron(electron, i) {
    return (
      <Circle
        key={i}
        x={electron.x}
        y={electron.y}
        draggable={!this.state.addingItems && !this.state.removingItems}
        radius={7}
        fill="black"
        onClick={e => this.handleItemClick(e)}
        onTap={e => this.handleItemClick(e)}
        onDragStart={() => this.handleDragStart()}
        onDragEnd={e => {
          this.updateElectronPosition({
            ...electron,
            x: e.target.x(),
            y: e.target.y()
          });
        }}
      />
    );
  }

  renderBond(bond, i) {
    return (
      <Line
        key={i}
        points={bond.points}
        stroke={bond.stroke}
        strokeWidth={7}
        onTap={e => this.handleBondClick(bond)}
        onClick={() => this.handleBondClick(bond)}
      />
    )
  }

  renderButtons() {
    return (
      <div
        className="covalentBonding-buttons"
      >
        {/*Element Buttons*/}
        <div
          className="addElementButtons"
        >
          {
            this.state.currentProblem.elements.map((element, i) => {
              return (
                <Button
                  key={i}
                  onClick={() => this.handleButtonClick('elements', element)}
                  color={this.state.addingItems && this.state.addingItems === element.symbol ? 'green' : null}
                >
                  Add {element.symbol}
                </Button>

              );
            })
          }
        </div>


        <div
          className="covalent-standard-buttons"
        >
          {/*Electrons*/}
          <Button
            onClick={() => this.handleButtonClick('electrons')}
            color={this.state.addingItems && this.state.addingItems === 'electrons' ? 'green' : null}
          >
            Add Electrons
          </Button>

          {/*Bonds*/}
          {
            this.props.problemType !== 'lewisDotStructuresOfAtoms' && (
              <Button
                onClick={() => this.handleButtonClick('bonds')}
                color={this.state.addingItems && this.state.addingItems === 'bonds' ? 'green' : null}
              >
                Add Bonds
              </Button>
            )

          }

          {/*Remove*/}
          <Button
            onClick={() => this.handleButtonClick('remove')}
            color={this.state.removingItems ? 'red': null}
          >
            Erase Item
          </Button>

          {/*Check Answer*/}
          <Button
            onClick={() => {
              if (this.state.answerIsCorrect) {
                this.advanceToNextProblem()
              } else {
                this.checkAnswer()
              }
            }}
            color='green'
          >
            { this.state.answerIsCorrect ? 'Next Problem' : 'Check Answer' }
          </Button>

        </div>

      </div>
    )
  }

  renderFormula() {

    return (
      <div className="covalentBonding-formula">
        {this.state.currentProblem.elements.map((element, i) => {
          const subscript = element.subscript !== 1 ? element.subscript : null;
          return (
            <span
              key={i}
            >
              {element.symbol}
              {
                subscript && (
                  <sub>{subscript}</sub>
                )
              }
            </span>
          )
        })}
        {
          this.state.currentProblem.charge !== 0 && (
            <sup>{`${this.state.currentProblem.charge * -1}-`}</sup>
          )
        }
      </div>
    )
  }

  render() {
    // Stage is a div container
    // Layer is actual canvas element (so you may have several canvases in the stage)
    // And then we have canvas shapes inside the Layer

    return (
      <div
        className="conCovalentBonding"
      >

        <Container
          text
        >
          {
            this.renderFormula()
          }

          {/*Add/Remove Buttons*/}
          { this.renderButtons() }



          <div
            className="covalentBonding-incorrect-notice"
          >
            {
              !this.state.answerIsCorrect && this.state.numberOfTimesChecked > 0 && (
                <span>
                  Sorry your structure is incorrect. You have checked this problem {this.state.numberOfTimesChecked} times.
                </span>
              )
            }

            {
              this.state.answerIsCorrect && (
                <span className="correct">
                  You figured out the correct structure!
                </span>
              )
            }
          </div>

        </Container>

        <div
          className={
            `pageWrapper covalentBondingAnswerSection${this.state.addingItems ? ' addingItems' : ''}
           ${this.state.removingItems ? ' removingItems' : ''}
           ${this.state.dragging ? ' dragging' : ''}`
          }
        >

          <Stage
            width={window.innerWidth - 30}
            height={window.innerHeight - 260}

            onContentClick={(e) => this.handleClick(e)}
            onContentMouseup={(e) => this.handleMouseUp(e)}
            onMouseMove={(e) => this.handleMouseMove(e)}

            onTouchStart={(e) => this.handleTouchStart(e)}
            onTouchMove={(e) => this.handleTouchMove(e)}
            onTouchEnd={(e) => this.handleTouchEnd(e)}


          >
            <Layer

            >
              {/*Render the elements*/}
              {
                this.state.userElements.map((element, i) => {
                  return this.renderSymbol(element, i);
                })
              }

              {/*Render the electrons*/}
              {
                this.state.userElectrons.map((electron, i) => {
                  return this.renderElectron(electron, i);
                })
              }

              {/*Render bonds*/}
              {
                this.state.userBonds.map((bond, i) => {
                  return this.renderBond(bond, i)
                })
              }

              {/* Render bond being drawn */}
              {
                this.state.addingItems === 'bonds' && this.state.currentBondPoint1 && this.state.currentBondPoint2 &&
                <Line
                  points={[
                    this.state.currentBondPoint1[0],
                    this.state.currentBondPoint1[1],
                    this.state.currentBondPoint2[0],
                    this.state.currentBondPoint2[1]
                  ]}
                  stroke='black'
                  strokeWidth={3}
                />
              }
            </Layer>
          </Stage>
        </div>

        <ConfirmModal
          open={this.state.notLoggedInModalOpen}
          title="Not Logged In"
          confirmButtonLabel="Ok"
          cancelButtonLabel="Cancel"
          confirmText="You are not logged in. You can do the practice activities but your results will not be saved."
          confirmButtonColor="green"
          onConfirm={() => this.setState({ notLoggedInModalOpen: false })}
          onCancel={() => this.setState({ notLoggedInModalOpen: false })}
        />

      </div>
    );
  }
}

function CovalentStructuresWithMutation(props) {
  const user = auth0Client.getProfile();
  return (
    <Mutation
      mutation={SAVE_PRACTICE_RESULTS_WITH_USER_ID}
    >
      {saveProblem =>
        <CovalentStructures
          saveProblem={saveProblem}
          user={user}
          problems={props.problems}
          problemType={props.problemType}
        />
      }
    </Mutation>
  )
}

export default CovalentStructuresWithMutation;
