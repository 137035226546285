import moment from 'moment'
import { createRandomId } from "./utils";
// import problemTypes from './problemTypeConstants';
// import { getUserIdFromLocalStorage } from './user';

export function createStringifiedResult(problem, numberOfTimesChecked, problemIsCorrect, problemTypeId, userId) {
  const timestamp = moment().toISOString();

  const newProblem = {
    id: createRandomId(12),
    problem: problem,
    typeId: problemTypeId,
    numberOfTimesChecked: numberOfTimesChecked,
    answerIsCorrect: problemIsCorrect,
    timestamp,
    userId,
  };

  const stringifiedResult = JSON.stringify(newProblem);
  return stringifiedResult;
}

// export function saveProblemToLocalStorage(problem, numberOfTimesChecked, problemIsCorrect, problemTypeId) {
//   const grokChemistry = JSON.parse(localStorage.getItem('grokChemistry')) || {};
//   const savedProblems = grokChemistry.savedProblems || [];
//   const timestamp = moment().toISOString();
//
//   const newProblem = {
//     id: createRandomId(12),
//     problem: problem,
//     typeId: problemTypeId,
//     numberOfTimesChecked: numberOfTimesChecked,
//     answerIsCorrect: problemIsCorrect,
//     timestamp,
//     userId: getUserIdFromLocalStorage()
//   }
//
//   savedProblems.push(newProblem);
//
//   grokChemistry.savedProblems = savedProblems;
//   localStorage.setItem('grokChemistry', JSON.stringify(grokChemistry));
// }

// export function clearAllSavedProblems() {
//   const grokChemistry = JSON.parse(localStorage.getItem('grokChemistry')) || {};
//   grokChemistry.savedProblems = [];
//   localStorage.setItem('grokChemistry', JSON.stringify(grokChemistry));
// }

export default {
  // saveProblemToLocalStorage,
  createStringifiedResult,
  // clearAllSavedProblems,
};
