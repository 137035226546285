import React, { useState } from 'react';
import moment from 'moment';
import { Query } from "react-apollo";
import {Container, Dropdown, Grid, Loader } from 'semantic-ui-react';

// import ConfirmModal from '../shared/ConfirmModal';
// import { clearAllSavedProblems } from '../../utils/saveProblem';
import problemTypes from '../../utils/problemTypeConstants';
import withState from '../../state/withState';
import { GET_PRACTICE_RESULTS_BY_USER_ID, GET_PRACTICE_RESULTS_SHARED_TO_USER_ID } from '../../gql/queries/queries';
import auth0Client from '../../auth/Auth';
import { sortCompletedProblems, extractStudentListFromSubmittedProblems } from '../utils';

function SharedWithMe(props) {
  const [selectedSection, updateSelectedSection] = useState('all');
  const [selectedStudentEmail, updateSelectedStudentEmail] = useState('allStudents');

  const isAuthenticated = auth0Client.isAuthenticated();
  const user = auth0Client.getProfile();

  if (!isAuthenticated) {
    return (
      <div
        className="pageWrapper"
      >
        Please log in in order to view your students' results.
      </div>

    )
  }

  if (!user) {
    return (
      <div
        className="pageWrapper"
      >
        <Loader
          active
          size="massive"
        />
      </div>
    )
  }

  if (!user.email_verified) {
    return (
      <div
        className="pageWrapper"
      >
        You must check your email and verify your account before you can view this page.
      </div>
    )
  }

  return (
    <div
      className="pageWrapper"
    >


        <Query
          query={GET_PRACTICE_RESULTS_SHARED_TO_USER_ID}
          variables={{ userId: user.sub }}
          fetchPolicy="network-only"
          pollInterval={10000}
        >
          {({ loading, error, data }) => {
            if (loading) return (
              <div
                className="pageWrapper"
              >
                <Loader active size="massive"/>
              </div>
            );
            if (error) return `Error! ${error}`;

            const completedProblems = JSON.parse(data.getPracticeResultsSharedToUserId);
            const sortedProblems = sortCompletedProblems(completedProblems);

            let studentList = [];
            if (selectedSection !== 'all') {
              studentList = extractStudentListFromSubmittedProblems(
                completedProblems.filter(
                  problem => (
                    problem.submission &&
                    problem.submission.classSection === selectedSection
                  )
                )
              );
            } else {
              studentList = extractStudentListFromSubmittedProblems(completedProblems);
            }

            if (!completedProblems.length) {
              return (
                <div className="grokCard">
                  Sorry, no results were found.
                </div>
              )
            }

            {
                return (
                  <Container>
                    <Grid>
                      <Grid.Row
                        centered
                      >
                        <h2>Submitted Student Work</h2>
                      </Grid.Row>
                      <Grid.Row columns={12}>
                        <Grid.Column width={4}>
                          <Dropdown
                            className="modalInput"
                            onChange={(e, data) => updateSelectedSection(data.value)}
                            placeholder="Filter by Class Section"
                            value={selectedSection}
                            name="classSection"
                            selection
                            options={[
                              { key: 'all', value: 'all', text: 'All Sections' },
                              { key: '1', value: '1', text: 'Section 1' },
                              { key: '2', value: '2', text: 'Section 2' },
                              { key: '3', value: '3', text: 'Section 3' },
                              { key: '4', value: '4', text: 'Section 4' },
                              { key: '5', value: '5', text: 'Section 5' },
                              { key: '6', value: '6', text: 'Section 6' },
                              { key: '7', value: '7', text: 'Section 7' },
                              { key: '8', value: '8', text: 'Section 8' },
                              { key: '9', value: '9', text: 'Section 9' },
                              { key: '10', value: '10', text: 'Section 10' },
                              { key: '11', value: '11', text: 'Section 11' },
                              { key: '12', value: '12', text: 'Section 12' },
                              { key: '13', value: '13', text: 'Section 13' },
                              { key: '14', value: '14', text: 'Section 14' },
                              { key: '15', value: '15', text: 'Section 15' },
                              { key: '16', value: '16', text: 'Section 16' },
                              { key: '17', value: '17', text: 'Section 17' },
                              { key: '18', value: '18', text: 'Section 18' },
                              { key: '19', value: '19', text: 'Section 19' },
                              { key: '20', value: '20', text: 'Section 20' },
                            ]}
                          />
                        </Grid.Column>

                        <Grid.Column width={4}>
                          <Dropdown
                            className="modalInput"
                            onChange={(e, data) => updateSelectedStudentEmail(data.value)}
                            placeholder="Filter by Student"
                            value={selectedStudentEmail}
                            name="selectedStudentEmail"
                            selection
                            options={[
                              { key: 'allStudents', value: 'allStudents', text: 'All Students' },
                              ...studentList
                            ]}
                          />
                        </Grid.Column>
                      </Grid.Row>

                    </Grid>

                    {
                      sortedProblems.map(submission => {
                        if (!submission.problems.length) {
                          return null;
                        } else if (selectedSection !== 'all' && selectedSection !== submission.classSection) {
                          return null;
                        } else if (selectedStudentEmail !== 'allStudents' && selectedStudentEmail !== submission.studentEmail) {
                          return null;
                        }

                        return (
                          <Grid className="grokCard" key={submission.submissionId}>
                            <Grid.Row columns={12}>

                              <Grid.Column width={4}>
                                <h4>{submission.submissionName}</h4>
                              </Grid.Column>

                              <Grid.Column width={4}>
                                {`${submission.studentLastName}, ${submission.studentFirstName}`}
                              </Grid.Column>

                              <Grid.Column width={4}>
                                {`Section ${submission.classSection}`}
                              </Grid.Column>
                              {/*<Grid.Column width={4}>*/}
                              {/*  <div>{submission.studentFirstName} {submission.studentLastName}</div>*/}
                              {/*</Grid.Column>*/}

                              {/*<Grid.Column width={4}>*/}
                              {/*  <div>{submission.studentEmail}</div>*/}
                              {/*</Grid.Column>*/}

                            </Grid.Row>

                            <Grid.Row columns={4}>
                              <Grid.Column>
                                <strong>Type</strong>
                              </Grid.Column>

                              <Grid.Column>
                                <strong>Name</strong>
                              </Grid.Column>

                              <Grid.Column>
                                <strong>Date</strong>
                              </Grid.Column>

                              <Grid.Column>
                                <strong>Times Checked</strong>
                              </Grid.Column>
                            </Grid.Row>

                            {
                              submission.problems.map(problem => {
                                return (
                                  <Grid.Row columns={4} key={problem.id}>
                                    {/*Problem Type*/}
                                    <Grid.Column
                                    >
                                      {problemTypes[problem.typeId].name}
                                    </Grid.Column>

                                    {/*Problem Name*/}
                                    <Grid.Column
                                    >
                                      {problem.problem.name}
                                    </Grid.Column>

                                    {/*Date completed*/}
                                    <Grid.Column
                                    >
                                      {moment(problem.timestamp).format('MMMM DD, YYYY')}
                                    </Grid.Column>

                                    {/*Number of Times Checked*/}
                                    <Grid.Column
                                    >
                                      {problem.numberOfTimesChecked}
                                    </Grid.Column>
                                  </Grid.Row>
                                )
                              })
                            }
                          </Grid>
                        )
                      })
                    }
                  </Container>
                )
            }
          }}
        </Query>

    </div>

  )


}

export default SharedWithMe


