import elements from './covalentElements';

const compoundNames = {
  carbonDioxide: 'Carbon Dioxide',
  water: 'Water',
  methane: 'Methane',
  ethane: 'Ethane',
  propane: 'Propane',
  butane: 'Butane',
  pentane: 'Pentane',
  ethene: 'Ethene',
  propene: 'Propene',
  butene: 'Butene', // C4H8
  acetylene: 'Acetylene',
  carbonMonoxide: 'CarbonMonoxide', // TODO - verify this works!
  ammonia: 'Ammonia',
  phoshporusTriHydride: 'Phosphorus Trihydride',
  phoshporusTriChloride: 'Phosphorus Trichloride',
  sulfate: 'Sulfate',
  nitrate: 'Nitrate',
  nitricAcid: 'Nitric Acid',
  hydrochloricAcid: 'Hydrochloric Acid',
  hydroiodicAcid: 'Hydroiodic Acid',
  sulfuricAcid: 'Sulfuric Acid',
  nitrousOxide: 'Nitrous Oxide',
  nitrogen: 'Nitrogen',
  oxygen: 'Oxygen',
  hydrogen: 'Hydrogen',
  fluorine: 'Fluorine',
  chlorine: 'Chlorine',
  bromine: 'Bromine',
  iodine: 'Iodine',
  carbonTetrafluoride: 'Carbon Tetrafluoride',
  carbonTetrachloride: 'Carbon Tetrachloride',



  // add HNO3, NO2, NO, SO42-, etc
  // account for charge
  // organic chem functional groups
};

// export const covalentBondingProblems = [
//   {
//     difficulty: 2,
//     name: compoundNames.carbonMonoxide,
//     charge: 0,
//     elements: [
//       {
//         ...elements.carbon,
//         subscript: 1,
//         numberOfBonds: [3],
//       },
//       {
//         ...elements.oxygen,
//         subscript: 1,
//         numberOfBonds: [3],
//       },
//     ]
//   },
// ]

export const covalentBondingProblems = [
  {
    difficulty: 1,
    name: compoundNames.carbonDioxide,
    charge: 0,
    elements: [
      {
        ...elements.carbon,
        subscript: 1,
      },
      {
        ...elements.oxygen,
        subscript: 2,
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.water,
    charge: 0,
    elements: [
      {
        ...elements.hydrogen,
        subscript: 2,
      },
      {
        ...elements.oxygen,
        subscript: 1,
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.methane,
    charge: 0,
    elements: [
      {
        ...elements.carbon,
        subscript: 1,
      },
      {
        ...elements.hydrogen,
        subscript: 4,
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.ethane,
    charge: 0,
    elements: [
      {
        ...elements.carbon,
        subscript: 2,
      },
      {
        ...elements.hydrogen,
        subscript: 6,
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.propane,
    charge: 0,
    elements: [
      {
        ...elements.carbon,
        subscript: 3,
      },
      {
        ...elements.hydrogen,
        subscript: 8,
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.butane,
    charge: 0,
    elements: [
      {
        ...elements.carbon,
        subscript: 4,
      },
      {
        ...elements.hydrogen,
        subscript: 10,
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.pentane,
    charge: 0,
    elements: [
      {
        ...elements.carbon,
        subscript: 5,
      },
      {
        ...elements.hydrogen,
        subscript: 12,
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.ethene,
    charge: 0,
    elements: [
      {
        ...elements.carbon,
        subscript: 2,
      },
      {
        ...elements.hydrogen,
        subscript: 4,
      },
    ]
  },
  {
    difficulty: 2,
    name: compoundNames.propene,
    charge: 0,
    elements: [
      {
        ...elements.carbon,
        subscript: 3,
      },
      {
        ...elements.hydrogen,
        subscript: 6,
      },
    ]
  },
  {
    difficulty: 2,
    name: compoundNames.butene,
    charge: 0,
    elements: [
      {
        ...elements.carbon,
        subscript: 4,
      },
      {
        ...elements.hydrogen,
        subscript: 8,
      },
    ]
  },
  {
    difficulty: 2,
    name: compoundNames.acetylene,
    charge: 0,
    elements: [
      {
        ...elements.carbon,
        subscript: 2,
      },
      {
        ...elements.hydrogen,
        subscript: 2,
      },
    ]
  },
  {
    difficulty: 2,
    name: compoundNames.carbonMonoxide,
    charge: 0,
    elements: [
      {
        ...elements.carbon,
        subscript: 1,
        numberOfBonds: [3],
      },
      {
        ...elements.oxygen,
        subscript: 1,
        numberOfBonds: [3],
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.ammonia,
    charge: 0,
    elements: [
      {
        ...elements.nitrogen,
        subscript: 1,
      },
      {
        ...elements.hydrogen,
        subscript: 3,
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.phoshporusTriHydride,
    charge: 0,
    elements: [
      {
        ...elements.phosphorus,
        subscript: 1,
      },
      {
        ...elements.hydrogen,
        subscript: 3,
      },
    ]
  },
  {
    difficulty: 3,
    name: compoundNames.sulfate,
    charge: -2,
    elements: [
      {
        ...elements.sulfur,
        subscript: 1,
        numberOfBonds: [4, 6],
        bondingElectrons: [8, 12]
      },
      {
        ...elements.oxygen,
        subscript: 4,
        numberOfBonds: [1, 2],
        bondingElectrons: [8]
      },
    ]
  },
  {
    difficulty: 3,
    name: compoundNames.nitrate,
    charge: -1,
    elements: [
      {
        ...elements.nitrogen,
        subscript: 1,
        numberOfBonds: [4],
        bondingElectrons: [8]
      },
      {
        ...elements.oxygen,
        subscript: 3,
        numberOfBonds: [1, 2],
        bondingElectrons: [8]
      },
    ]
  },
  {
    difficulty: 3,
    name: compoundNames.nitricAcid,
    charge: 0,
    elements: [
      {
        ...elements.hydrogen,
        subscript: 1,
      },
      {
        ...elements.nitrogen,
        subscript: 1,
        numberOfBonds: [4],
        bondingElectrons: [8]
      },
      {
        ...elements.oxygen,
        subscript: 3,
        numberOfBonds: [1, 2],
        bondingElectrons: [8]
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.phoshporusTriChloride,
    charge: 0,
    elements: [
      {
        ...elements.phosphorus,
        subscript: 1,
      },
      {
        ...elements.chlorine,
        subscript: 3,
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.hydrochloricAcid,
    charge: 0,
    elements: [
      {
        ...elements.hydrogen,
        subscript: 1,
      },
      {
        ...elements.chlorine,
        subscript: 1,
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.hydroiodicAcid,
    charge: 0,
    elements: [
      {
        ...elements.hydrogen,
        subscript: 1,
      },
      {
        ...elements.iodine,
        subscript: 1,
      },
    ]
  },
  {
    difficulty: 3,
    name: compoundNames.sulfuricAcid,
    charge: 0,
    elements: [
      {
        ...elements.hydrogen,
        subscript: 2,
      },
      {
        ...elements.sulfur,
        subscript: 1,
        numberOfBonds: [4, 6],
        bondingElectrons: [8, 12]
      },
      {
        ...elements.oxygen,
        subscript: 4,
        numberOfBonds: [1, 2],
        bondingElectrons: [8]
      },
    ]
  },
  {
    difficulty: 4,
    name: compoundNames.nitrousOxide,
    charge: 0,
    elements: [
      {
        ...elements.nitrogen,
        subscript: 2,
        numberOfBonds: [4],
        bondingElectrons: [8]
      },
      {
        ...elements.oxygen,
        subscript: 1,
        numberOfBonds: [1, 2],
        bondingElectrons: [8]
      },
    ]
  },
  {
    difficulty: 2,
    name: compoundNames.nitrogen,
    charge: 0,
    elements: [
      {
        ...elements.nitrogen,
        subscript: 2,
        numberOfBonds: [3],
        bondingElectrons: [8]
      },
    ]
  },
  {
    difficulty: 2,
    name: compoundNames.oxygen,
    charge: 0,
    elements: [
      {
        ...elements.oxygen,
        subscript: 2,
        numberOfBonds: [2],
        bondingElectrons: [8]
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.hydrogen,
    charge: 0,
    elements: [
      {
        ...elements.hydrogen,
        subscript: 2,
        numberOfBonds: [1],
        bondingElectrons: [2]
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.fluorine,
    charge: 0,
    elements: [
      {
        ...elements.fluorine,
        subscript: 2,
        numberOfBonds: [1],
        bondingElectrons: [8]
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.chlorine,
    charge: 0,
    elements: [
      {
        ...elements.chlorine,
        subscript: 2,
        numberOfBonds: [1],
        bondingElectrons: [8]
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.bromine,
    charge: 0,
    elements: [
      {
        ...elements.bromine,
        subscript: 2,
        numberOfBonds: [1],
        bondingElectrons: [8]
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.iodine,
    charge: 0,
    elements: [
      {
        ...elements.iodine,
        subscript: 2,
        numberOfBonds: [1],
        bondingElectrons: [8]
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.carbonTetrafluoride,
    charge: 0,
    elements: [
      {
        ...elements.carbon,
        subscript: 1,
        numberOfBonds: [4],
        bondingElectrons: [8]
      },
      {
        ...elements.fluorine,
        subscript: 4,
        numberOfBonds: [1],
        bondingElectrons: [8]
      },
    ]
  },
  {
    difficulty: 1,
    name: compoundNames.carbonTetrachloride,
    charge: 0,
    elements: [
      {
        ...elements.carbon,
        subscript: 1,
        numberOfBonds: [4],
        bondingElectrons: [8]
      },
      {
        ...elements.chlorine,
        subscript: 4,
        numberOfBonds: [1],
        bondingElectrons: [8]
      },
    ]
  },
];

export default covalentBondingProblems;
