import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import './styles/index'

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more testComponents service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

ReactDOM.render(
    <App />,
  document.getElementById("root")
);

serviceWorker.unregister();
