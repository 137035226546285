// import gql from 'graphql-tag';
import { gql } from 'apollo-boost';


// Type definitions define the "shape" of your data and specify
// which ways the data can be fetched from the GraphQL server.
const typeDefs = gql`
  # Comments in GraphQL are defined with the hash (#) symbol.
  
  type cations {
    name: String
  }

  type LiveClassroomItem {
    type: String
    prompt: String
  },
`;

export default typeDefs;

export const CATION_QUERY = gql`query
{
  # Adds an alias
  cationDataset: cations {
    name
    symbol {
      element
      subscript
    }
    chargeDisplay
    type
    chargeMagnitude
    polyatomic
  }
}`;

export const ANION_QUERY = gql`query
{
  anionDataset: anions {
    name
    symbol {
      element
      subscript
    }
    chargeDisplay
    type
    chargeMagnitude
    polyatomic
  }
}`;

export const LIVE_CLASSROOM_QUERY = gql`query($token: String!, $email: String!, $studentGroupPhrase: String!)
{
  getLiveClassroomItem(token: $token, email: $email, studentGroupPhrase: $studentGroupPhrase)
}`;

export const ASSIGNMENT_QUERY = gql`query($assignmentPhrase: String!)
{
  getAssignmentByPhrase(assignmentPhrase: $assignmentPhrase) {
    assignmentPhrase
    complete
    problems
    title
  }
}`;

export const ASSIGNMENTS_QUERY = gql`query($token: String!, $assignmentPhrases: [String]!)
{
  getAssignmentsByPhrase(assignmentPhrases: $assignmentPhrases, token: $token) {
    assignmentPhrase
    complete
    problems
    title
  }
}`;

export const GET_PRACTICE_RESULTS_BY_USER_ID = gql`
    query($userId: String!){
        getPracticeResultsByUserId(userId: $userId)
    }
`

export const GET_PRACTICE_RESULTS_SHARED_TO_USER_ID = gql`
    query($userId: String!){
        getPracticeResultsSharedToUserId(userId: $userId)
    }
`

export const SAVE_PRACTICE_RESULTS_WITH_USER_ID = gql`
    mutation($userId: String! $stringifiedResult: String!){
        savePracticeResultsByUserId(userId: $userId, stringifiedResult: $stringifiedResult)
    }
`

export const SUBMIT_PRACTICE_RESULTS = gql`
    mutation($problemResultIds: [String] $submissionName: String! $teacherEmail: String! $classSection: String!){
        submitPracticeResults(problemResultIds: $problemResultIds, submissionName: $submissionName, teacherEmail: $teacherEmail, classSection: $classSection)
    }
`

export const GET_USER_PROFILE = gql`
    query($userId: String!){
        getUserProfile(userId: $userId) {
            firstName
            lastName
            email
            role
            userId
        }
    }
`

export const UPDATE_USER_PROFILE = gql`
    mutation ($userId: String! $firstName: String! $lastName: String! $role: String!){
        updateUserProfile(userId: $userId firstName: $firstName lastName: $lastName role: $role) {
            firstName
            lastName
            email
            role
            userId
        }
    }
`
