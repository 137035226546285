import React, { Component } from 'react'
import {
  withRouter
} from 'react-router-dom'
import { Icon, Menu, Segment, Sidebar } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import erlenmeyerFlask from '../../images/erlenmeyer-flask.png';
import routeConstants from '../routes/constants';
import versionData from '../../../package.json';
import auth0Client from "../../auth/Auth";
import {GET_USER_PROFILE} from "../../gql/queries/queries";
import {Query} from "react-apollo";

export class AppSidebar extends Component {
  state = { visible: false, width: 'thin' };

  handleHideClick = () => this.setState({ visible: false, width: 'very thin' });
  handleShowClick = () => this.setState({ visible: true, width: 'thin' });
  handleSidebarHide = () => this.setState({ visible: false, width: 'very thin' });

  handleNavigation(location) {
    const { history } = this.props;
    setTimeout(() => this.setState({ visible: false }), 10);
    history.push(location)
  }

  handleLoginLogout() {
    const isAuthenticated = auth0Client.isAuthenticated();

    // const signOut = () => {
    //   auth0Client.signOut();
    //   this.props.history.replace('/');
    // };
    if (!isAuthenticated) {
      auth0Client.signIn();
    } else if (isAuthenticated) {
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  render() {
    const user = auth0Client.getProfile();
    const { width } = this.state;
    const isAuthenticated = auth0Client.isAuthenticated();

    // OLD SITE: defscience.com
    if (['defscience.com', 'www.defscience.com'].includes(window.location.host)) {
      return (
        <div>
          <div
            className="conTopbar"
          >
            <Icon size="big" onClick={() => this.setState({ visible: true, width: 'thin' })} />
            <div className="logoAndName">
              <img src={erlenmeyerFlask} alt="erlenmeyerFlask"/>
              <Link to={routeConstants.home}><h2>Grok Chemistry</h2></Link>
            </div>
          </div>
          {this.props.children}
        </div>
      );
    }

    // NEW SITE: grokchemistry.com
    return (
      <div>
        <div>
          <Sidebar.Pushable as={Segment}>

            {/*Topbar*/}
            <div
              className="conTopbar"
            >
              <Icon name='bars' size="big" onClick={() => this.setState({ visible: true, width: 'thin' })} />
              <div className="logoAndName">
                <img src={erlenmeyerFlask} alt="erlenmeyerFlask"/>
                <Link to={routeConstants.home}><h2>Grok Chemistry</h2></Link>
              </div>
            </div>
            <Sidebar
              className="conSidebar"
              as={Menu}
              animation='overlay'
              icon='labeled'
              inverted
              onHide={this.handleSidebarHide}
              vertical
              visible={this.state.visible}
              width={width}
            >

              <Menu.Item as='a' onClick={() => this.handleNavigation(routeConstants.home)}>
                {/*<Icon name='home' />*/}
                {width === 'thin' ? (<div>Practice <br />Activities</div>): null}
              </Menu.Item>

              {/*<Menu.Item as='a' onClick={() => this.handleNavigation(routeConstants.atomicStructure.bohrModels)}>*/}
              {/*  Bohr Models*/}
              {/*</Menu.Item>*/}

              {/*<Menu.Item as='a' onClick={() => this.handleNavigation(routeConstants.periodicTable.sortingIntoGroups)}>*/}
              {/*  Periodic Table*/}
              {/*</Menu.Item>*/}

              {/*<Menu.Item as='a' onClick={() => this.handleNavigation(routeConstants.ionicBonding.ionicBondingPuzzles)}>*/}
              {/*  Ionic Bonding*/}
              {/*</Menu.Item>*/}

              {/*<Menu.Item as='a' onClick={() => this.handleNavigation(routeConstants.ionicBonding.ionicBondingFormulas)}>*/}
              {/*  Ionic Formulas*/}
              {/*</Menu.Item>*/}

              {/*<Menu.Item as='a' onClick={() => this.handleNavigation(routeConstants.covalentBonding.lewisDotStructures)}>*/}
              {/*  Lewis Dot Structures*/}
              {/*</Menu.Item>*/}

              {/*<Menu.Item as='a' onClick={() => this.handleNavigation(routeConstants.covalentBonding.covalentBonding)}>*/}
              {/*  Covalent Structures*/}
              {/*</Menu.Item>*/}

              <Menu.Item as='a' onClick={() => this.handleNavigation(routeConstants.completedProblems)}>
                My Completed Problems
              </Menu.Item>

              {
                user && user.sub && (
                  <Query
                    query={GET_USER_PROFILE}
                    variables={{ userId: user.sub }}
                    fetchPolicy="cache-and-network"
                  >
                    {({ loading, error, data, refetch }) => {
                      if (data) {
                        const { getUserProfile: userProfile } = data;

                        if (userProfile && userProfile.role === 'teacher') {
                          return (
                            <Menu.Item as='a' onClick={() => this.handleNavigation(routeConstants.problemsSharedWithMe)}>
                              Submitted Student Work
                            </Menu.Item>
                          )
                        } else {
                          return null;
                        }
                      }
                    }
                    }
                  </Query>
                )
              }

              {
                user && user.sub && (
                  <Menu.Item as='a' onClick={() => this.handleNavigation(routeConstants.profile)}>
                    Profile
                  </Menu.Item>
                )
              }

              <Menu.Item as='a' onClick={() => this.handleLoginLogout(routeConstants.login)}>
                {isAuthenticated ? 'Log out' : 'Login / Sign up'}
              </Menu.Item>

              {/*Close Menu*/}
              {
                this.state.width === 'thin' ?
                  <Menu.Item as='a' onClick={this.handleHideClick}>
                    <Icon name='angle double left' />
                  </Menu.Item> :
                  <Menu.Item as='a' onClick={this.handleShowClick}>
                    <Icon name='angle double right' />
                  </Menu.Item>
              }
              <div
                className="appVersion"
              >
                {versionData.version}
              </div>
            </Sidebar>

            <Sidebar.Pusher>
              <Segment basic>
                <div
                  className="conActivityWrapper"
                >
                  {this.props.children}
                </div>
              </Segment>
            </Sidebar.Pusher>

          </Sidebar.Pushable>
        </div>
      </div>
    )
  }
}

// add Router
const SidebarWithRouter = withRouter(AppSidebar);

export default SidebarWithRouter;
