export const lewisDotStructureProblems = [
  {
    name: 'Hydrogen',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'H',
        valence: 1,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Helium',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'He',
        valence: 2,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Lithium',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'Li',
        valence: 1,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Beryllium',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'Be',
        valence: 2,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Boron',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'B',
        valence: 3,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Carbon',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'C',
        valence: 4,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Nitrogen',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'N',
        valence: 5,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Oxygen',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'O',
        valence: 6,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Fluorine',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'F',
        valence: 7,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Neon',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'Ne',
        valence: 8,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Sodium',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'Na',
        valence: 1,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Magnesium',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'Mg',
        valence: 2,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Aluminum',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'Al',
        valence: 3,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Silicon',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'Si',
        valence: 4,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Phosphorus',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'P',
        valence: 5,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Sulfur',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'S',
        valence: 6,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Chlorine',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'Cl',
        valence: 7,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Argon',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'Ar',
        valence: 8,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Potassium',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'K',
        valence: 1,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Calcium',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'Ca',
        valence: 2,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Bromine',
    difficulty: 1,
    charge: 0,
    elements: [
      {
        symbol: 'Br',
        valence: 7,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Krypton',
    difficulty: 2,
    charge: 0,
    elements: [
      {
        symbol: 'Kr',
        valence: 8,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Rubidium',
    difficulty: 2,
    charge: 0,
    elements: [
      {
        symbol: 'Rb',
        valence: 1,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Strontium',
    difficulty: 2,
    charge: 0,
    elements: [
      {
        symbol: 'Sr',
        valence: 2,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Iodine',
    difficulty: 2,
    charge: 0,
    elements: [
      {
        symbol: 'I',
        valence: 7,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Xe',
    difficulty: 2,
    charge: 0,
    elements: [
      {
        symbol: 'Xe',
        valence: 8,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Cesium',
    difficulty: 2,
    charge: 0,
    elements: [
      {
        symbol: 'Cs',
        valence: 1,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Barium',
    difficulty: 2,
    charge: 0,
    elements: [
      {
        symbol: 'Ba',
        valence: 2,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Radon',
    difficulty: 2,
    charge: 0,
    elements: [
      {
        symbol: 'Rn',
        valence: 8,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Francium',
    difficulty: 2,
    charge: 0,
    elements: [
      {
        symbol: 'Fr',
        valence: 1,
        subscript: 1,
      }
    ]
  },
  {
    name: 'Radium',
    difficulty: 2,
    charge: 0,
    elements: [
      {
        symbol: 'Ra',
        valence: 2,
        subscript: 1,
      }
    ]
  }
]

export default lewisDotStructureProblems;
