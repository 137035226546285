import React from 'react';
import { compose, graphql } from 'react-apollo';
import { CATION_QUERY, ANION_QUERY } from '../../gql/queries/queries';

function ComponentToRender(props) {
  return (
    <div>
      This Component should receive the results of a GQL Query using compose
    </div>
  )
}

export default compose(
  graphql(CATION_QUERY, { name: 'cations' }),
  graphql(ANION_QUERY, { name: 'anions' }),
)(ComponentToRender);
