import React from 'react';
import withState from '../../state/withState.jsx'
import { Container, Grid } from 'semantic-ui-react'
// import grokChemistryLogo from '../../images/grokChemistryLogo.png';
import bohrModelExample from '../../images/bohrModelExample.png'
import sortPTExample from '../../images/sortPTExample.png';
import ionicBondingPuzzlesExample from '../../images/ionicBondingPuzzlesExample.png'
import ionicFormulasExample from '../../images/ionicFormulasExample.png';
import lewisDotExample from '../../images/lewisDotExample.png';
import covalentBondingExample from '../../images/covalentBondingExample.png';

function ActivityItem(props) {
  const { handleNavigation, img, title } = props;
  return (
    <Grid.Column
      className="activity"
      onClick={handleNavigation}
    >
      <h3>{title}</h3>
      <img src={img} alt="Activity"/>
    </Grid.Column>
  )
}

function Home(props) {
  const { history } = props;
  return (
    <div
      className="pageWrapper"
    >
      <Container>

        {/*<Grid>*/}
        {/*  <Grid.Row columns={1}>*/}
        {/*    <Grid.Column>*/}
        {/*      <img src={grokChemistryLogo} width={150} alt="defscienceLogo"/>*/}
        {/*    </Grid.Column>*/}
        {/*    /!*<Grid.Column width={12}>*!/*/}
        {/*    /!*  <h2>Chemistry Practice Activities</h2>*!/*/}
        {/*    /!*  <p>*!/*/}
        {/*    /!*    This site contains activities for teaching and learning chemistry.*!/*/}
        {/*    /!*    I hope to keep adding activities so check back soon!*!/*/}
        {/*    /!*  </p>*!/*/}

        {/*    /!*  <p>*!/*/}
        {/*    /!*    Let me know if you have any ideas or suggestions! You can contact me at <strong><a href="mailto:defsciencemail@gmail.com" rel="noopener noreferrer" target="_blank">defsciencemail@gmail.com</a></strong>.*!/*/}
        {/*    /!*  </p>*!/*/}
        {/*    /!*</Grid.Column>*!/*/}
        {/*  </Grid.Row>*/}
        {/*</Grid>*/}

        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <h2>Chemistry Practice Activities</h2>
            </Grid.Column>
          </Grid.Row>
        </Grid>


        <div className="activitiesList">
          <Grid>


            <h2>Atomic Structure</h2>
            <Grid.Row columns={3}>
              <ActivityItem
                handleNavigation={() => history.push('/bohr-models')}
                img={bohrModelExample}
                title="Bohr Models of the Atom"
              />
            </Grid.Row>

            <h2>The Periodic Table</h2>
            <Grid.Row columns={3}>
              <ActivityItem
                handleNavigation={() => history.push('/pt-groups')}
                img={sortPTExample}
                title="Sorting Elements into Groups"
              />
            </Grid.Row>

            <h2>Ionic Bonding</h2>
            <Grid.Row columns={3}>
              <ActivityItem
                handleNavigation={() => history.push('/puzzles')}
                img={ionicBondingPuzzlesExample}
                title="Ionic Bonding Puzzles"
              />

              <ActivityItem
                handleNavigation={() => history.push('/ionic-bonding-formulas')}
                img={ionicFormulasExample}
                title="Formulas of Ionic Compounds"
              />
            </Grid.Row>

            <h2>Covalent Bonding</h2>
            <Grid.Row columns={3}>
              <ActivityItem
                handleNavigation={() => history.push('/lewis-dot-structures')}
                img={lewisDotExample}
                title="Lewis Dot Structures of Atoms"
              />

              <ActivityItem
                handleNavigation={() => history.push('/covalent-structures')}
                img={covalentBondingExample}
                title="Lewis Dot Structures of Molecules"
              />
            </Grid.Row>
          </Grid>
        </div>
      </Container>
    </div>
  );
}

export default withState(Home);
