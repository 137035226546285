import React from "react";
import { Query } from "react-apollo";
import { CATION_QUERY } from '../../gql/queries/queries';

function ComponentToRender(props) {
  return (
    <div>
      This Component should receive the results of a GQL Query that wraps the component
    </div>
  )
}

const ComponentWithGQLQuery = () => (
  <Query
    query={CATION_QUERY}
  >
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error :(</p>;

      return (
        <ComponentToRender data={data}/>
      )
    }}
  </Query>
);

export default ComponentWithGQLQuery;


