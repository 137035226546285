import React from 'react';
import { AppStateContext } from './AppWithGlobalState';

function withState(WrappedComponent) {

  // Take in a component and wrap it with state and updater methods before returning it
  return class extends React.Component {
    render() {
      return (
        <AppStateContext.Consumer>
          {(AppState) => (
            <WrappedComponent
              AppState={AppState}
              {...this.props}
            >
            </WrappedComponent>
          )}
        </AppStateContext.Consumer>
      )
    }
  }
}

export default withState;
