import React from 'react';
import CovalentStructures from '../covalentBonding/CovalentStructures';
import problems from './problems';

function LewisDotStructures (props) {
  return (
    <CovalentStructures
      problems={problems}
      problemType="lewisDotStructuresOfAtoms"
    />
  )
}
export default LewisDotStructures;
