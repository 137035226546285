import React, { useState } from 'react';
// import { Link } from 'react-router-dom'
import { getRandomIntInclusive } from '../utils/utils';

const randomChemistryFacts = [
  { text: 'Lightning strikes produce Ozone, hence the characteristic smell after lightning storms.', source: 'www.zmescience.com' },
  { text: 'The only two non-silvery metals are gold and copper.', source: 'www.zmescience.com' },
  { text: 'Water expands when freezes, unlike other substances.', source: 'www.zmesciencecom' },
  { text: 'Glass is actually a liquid, it just flows very, very slowly.', source: 'www.zmescience.com' },
  { text: 'Every hydrogen atom in your body is likely 13.5 billion years old because they were created at the birth of the universe.', source: 'www.zmescience.com' },
  { text: 'Superfluid Helium defies gravity and climbs on walls.', source: 'www.zmescience.com' },
  { text: 'If you pour a handful of salt into a glass of water, the water level will go down.', source: 'www.zmescience.com' },
  { text: 'Diamond and graphite are both entirely made of carbon and nothing else.', source: 'www.zmescience.com' },
  { text: 'The rarest naturally-occurring element in the Earth’s crust is astatine.', source: 'www.zmescience.com' },
  { text: 'A company sells buckyballs sell for $167 million per gram. The only thing more expensive in the world is antimatter.', source: 'www.zmescience.com' },
  { text: 'DNA is a flame retardant.', source: 'www.zmescience.com' },
  { text: 'One inch of rain is equal to 10 inches of snow.', source: 'www.zmescience.com' },
  { text: 'A rubber tire is technically one single, giant, polymerized molecule.', source: 'www.zmescience.com'},
  { text: 'Your car’s airbags are packed with salt sodium azide, which is very toxic.', source: 'www.zmescience.com'},
  { text: 'Famed chemist Glenn Seaborg was the only person who could write his address in chemical elements.', source: 'www.zmescience.com'},
  { text: 'Air becomes liquid at -190°C.', source: 'www.zmescience.com'},
  { text: 'Mars is red because of iron oxide.', source: 'www.zmescience.com'},
]

function NotFound(props) {
  const [ randomChemFact ] = useState(randomChemistryFacts[getRandomIntInclusive(0, randomChemistryFacts.length - 1)])
  if (['defscience.com', 'www.defscience.com'].includes(window.location.host)) {
    setTimeout(() => {
      window.location.replace('https://www.grokchemistry.com')
    }, 5000)
    return (
      <div
        className="pageWrapper"
      >
        <h1>Chemistry Practice Activities</h1>
        <h3>
          Hey, you found my old domain! Try the new one, it's better:
        </h3>
        <h3>
          <a href='https://www.grokchemistry.com/'>www.grokchemistry.com</a>
        </h3>
        <p>
          You should be redirected there in just a moment...
        </p>
      </div>
    )
  }

  return (
    <div
      className="pageWrapper"
    >
      <h2>Page Not Found</h2>
      <h1>
        <a href='https://grokchemistry.com/'>Home</a>
      </h1>

      <div
        className="grokCard grokCard-fitContent"
      >
        <h4>Here's a random chemistry fact for you:
        </h4>
        <div>
          {randomChemFact.text}
        </div>
        <div>
          Source: <a target="_blank" href={`https://${randomChemFact.source}`}>{randomChemFact.source}</a>
        </div>
      </div>
    </div>
  )
}

export default NotFound;
