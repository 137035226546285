import React from 'react';
import auth0Client from "../../auth/Auth";
import { Loader } from "semantic-ui-react";

class Login extends React.Component {
  async componentDidMount() {
    // await auth0Client.handleAuthentication();
    // const user = auth0Client.getProfile();
    // console.log('user', user);
    //
    // if (user && user.sub) {
    //   this.props.history.replace('/');
    // } else {
    //   auth0Client.signIn();
    // }

    if (!auth0Client.isAuthenticated()) {
      console.log('user is not authenticated yet, logging in');
      auth0Client.signIn();

      return (
        <div
          className="pageWrapper"
        >
          <Loader
            active
            size="massive"
          />
        </div>
      )
    }
    // this.props.history.replace('/');
  }

  render() {
    return (
      <div
        className="pageWrapper"
      >
        <Loader
          active
          size="massive"
        >Logging in...</Loader>

      </div>
    );
  }
}
export default Login;
