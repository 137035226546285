import React, { useState } from 'react';
import {Button, Modal, Header, Input, Select, Dropdown} from "semantic-ui-react";
import {withFormik} from "formik";
import { Mutation } from 'react-apollo';
import { GET_USER_PROFILE, SUBMIT_PRACTICE_RESULTS } from '../../gql/queries/queries';

function SubmitProblemsModal(props) {
  const [modalOpen, toggleModalOpen] = useState(false);
  const [errorSubmitting, setSubmissionErrorState] = useState(false);
  const [submitting, toggleSubmitting] = useState(false);

  const {
    disabled,
    completedProblems,
    checkedProblems,
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    refetchProblems
  } = props;


  const handleSubmitProblems = (submitProblems) => {
    toggleSubmitting(true);
    submitProblems({
      variables: {
        problemResultIds: checkedProblems,
        submissionName: values.submissionName,
        teacherEmail: values.teacherEmail,
        classSection: values.classSection,
      }})
      .then(() => {
        toggleSubmitting(false);
        refetchProblems()
        toggleModalOpen(false);
      }).catch(e => {
        console.log('error', e);
        toggleSubmitting(false);
        setSubmissionErrorState(true);
    });
  }

  return (
    <Mutation mutation={SUBMIT_PRACTICE_RESULTS}>
      {
        submitProblems => (
          <Modal
            centered={false}
            open={modalOpen}
            trigger={
              <Button
                primary
                onClick={() => toggleModalOpen(true)}
                disabled={disabled}
                className="sumbitProblemsButton"
              >
                Submit Work
              </Button>}
            className="submitProblemsModalWrapper"
          >
            <Modal.Header>Submit Selected Problems</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Header>Number of problems in this submission: {checkedProblems.length}</Header>

                <form onSubmit={handleSubmit}>
                  <Input
                    className="modalInput"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.submissionName || ''}
                    name="submissionName"
                    placeholder="Submmission Name"
                    error={errors.submissionName && touched.submissionName}
                  />

                  <Dropdown
                    className="modalInput"
                    onChange={(e, data) => setFieldValue('classSection', data.value, true)}
                    placeholder="Class Section"
                    value={values.classSection || ''}
                    name="classSection"
                    selection
                    options={[
                      { key: '1', value: '1', text: 'Section 1' },
                      { key: '2', value: '2', text: 'Section 2' },
                      { key: '3', value: '3', text: 'Section 3' },
                      { key: '4', value: '4', text: 'Section 4' },
                      { key: '5', value: '5', text: 'Section 5' },
                      { key: '6', value: '6', text: 'Section 6' },
                      { key: '7', value: '7', text: 'Section 7' },
                      { key: '8', value: '8', text: 'Section 8' },
                      { key: '9', value: '9', text: 'Section 9' },
                      { key: '10', value: '10', text: 'Section 10' },
                      { key: '11', value: '11', text: 'Section 11' },
                      { key: '12', value: '12', text: 'Section 12' },
                      { key: '13', value: '13', text: 'Section 13' },
                      { key: '14', value: '14', text: 'Section 14' },
                      { key: '15', value: '15', text: 'Section 15' },
                      { key: '16', value: '16', text: 'Section 16' },
                      { key: '17', value: '17', text: 'Section 17' },
                      { key: '18', value: '18', text: 'Section 18' },
                      { key: '19', value: '19', text: 'Section 19' },
                      { key: '20', value: '20', text: 'Section 20' },

                    ]}
                  />

                  <Input
                    className="modalInput"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.teacherEmail || ''}
                    name="teacherEmail"
                    placeholder="Teacher's Email"
                    error={errors.teacherEmail && touched.teacherEmail}
                  />
                  {
                    errorSubmitting && (
                      <div>Unable to submit problems. Please check your teacher's email.</div>
                    )
                  }


                </form>

              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={() => {
                  setFieldValue('submissionName', '', false);
                  setFieldValue('classSection', '', false);
                  setFieldValue('teacherEmail', '', false);
                  toggleModalOpen(false)
                }}
              >
                Cancel
              </Button>
              <Button
                primary
                onClick={() => handleSubmitProblems(submitProblems)}
                disabled={submitting || Object.keys(errors).length > 0 || (!values.submissionName || !values.teacherEmail || !values.classSection)}
              >
                Submit Problems
              </Button>
            </Modal.Actions>
          </Modal>
        )
      }


    </Mutation>

  )
}


export default withFormik({
  mapPropsToValues: (props) => {
    // const { userProfile } = props;
    // return {
    //   firstName: userProfile.firstName || '',
    //   lastName: userProfile.lastName || '',
    //   role: userProfile.role || 'student',
    // }
  },

  // Custom sync validation
  validate: values => {
    const errors = {};

    if (!values.submissionName) {
      errors.submissionName = 'Required';
    }

    if (!values.teacherEmail) {
      errors.teacherEmail = 'Required';
    }

    return errors;
  },
  displayName: 'submitProblemForm',
})(SubmitProblemsModal);
