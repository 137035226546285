import Chance from 'chance';
import constants from './constants';

export function formatSymbol(ion, includeCharge = true, showSubscriptsForFormulas = false, numberOfIons) {
  const showParens = showSubscriptsForFormulas && ion.polyatomic && (numberOfIons > 1);

  let ionString = '';

  if (showParens) {
    ionString += '(';
  }

  // Put each of the elements along with subscripts
  ion.symbol.forEach((s) => {
    ionString += s.element;
    if (s.subscript) {
      ionString += `<sub>${s.subscript.toString()}</sub>`;
    }
  });

  // Now add the charge as a superscript
  if (includeCharge) {
    ionString += `<sup>${ion.chargeDisplay}</sup>`;
  }

  if (showParens) {
    ionString += ')';
  }

  if (showSubscriptsForFormulas && numberOfIons > 1) {
    ionString += `<sub>${numberOfIons}</sub>`;
  }

  return ionString;
}


export function createRandomId(length = 20, pool = constants.RANDOM_STRING_POOL) {
  return new Chance().string({ length, pool });
}

export function getRandomIntInclusive(mn, mx) {
  const min = Math.ceil(mn);
  const max = Math.floor(mx);
  return Math.floor(Math.random() * (max - min + 1)) + min; // The maximum is inclusive and the minimum is inclusive
}

export function getDistanceBetweenCoords(center, point) {
  // d = sqrt( (xp - xc)^2 + (yp - yc)^2 )
  const xValsSquared = Math.pow((point.x - center.x), 2); //eslint-disable-line
  const yValsSquared = Math.pow((point.y - center.y), 2); //eslint-disable-line
  const sum = xValsSquared + yValsSquared;
  return Math.pow(sum, 0.5); //eslint-disable-line
}

export default {
  formatSymbol,
  createRandomId,
  getRandomIntInclusive,
  getDistanceBetweenCoords,
};
