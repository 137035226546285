import React from 'react';
import { defaultState } from './defaultState';
import updaters from './updaters';

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
export const AppStateContext = React.createContext(defaultState);

class AppWithGlobalState extends React.Component {
  constructor(props) {
    super(props);

    // Bind 'this' to all the update functions using a for loop so that the 'this' context isn't lost
    let updatersWithProperThisBinding = {};
    for (let i = 0; i < updaters.length; i++) {
      updatersWithProperThisBinding[updaters[i].name] = updaters[i].bind(this)
    }

    // state also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      ...defaultState,
      ...updatersWithProperThisBinding
    };
  }

  render() {
    // The entire state is passed to the provider
    return (
      <AppStateContext.Provider value={this.state}>
        <div
          className={this.state.theme === 'darkMode' ? 'darkMode' : 'lightMode'}
        >
          {this.props.children}
        </div>
      </AppStateContext.Provider>
    );
  }
}

export default AppWithGlobalState;
