import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Sidebar from '../sidebar/Sidebar';
import Routes from '../routes/Routes'

function MainLayout(props) {
  return (
    <Router>
      <Sidebar>
        <Routes/>
      </Sidebar>
    </Router>
  );
}

export default MainLayout;
