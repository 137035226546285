import React, { useState } from 'react';
import moment from 'moment';
import { Query } from "react-apollo";
import {Container, Grid, Loader, Button, Modal, Checkbox} from 'semantic-ui-react';

// import ConfirmModal from '../shared/ConfirmModal';
// import { clearAllSavedProblems } from '../../utils/saveProblem';
import SubmitProblemsModal from "./SubmitProblemsModal";
import problemTypes from '../../utils/problemTypeConstants';
import withState from '../../state/withState';
import { GET_PRACTICE_RESULTS_BY_USER_ID, GET_PRACTICE_RESULTS_SHARED_TO_USER_ID } from '../../gql/queries/queries';
import auth0Client from '../../auth/Auth';
import { sortCompletedProblems } from '../utils';



function CompletedProblems(props) {
  const [submitWorkModal, toggleSubmitWorkModal] = useState(false);
  const [checkedProblems, updateCheckedProblems] = useState([]);

  const addRemoveProblem = (problem) => {
    if (checkedProblems.some(pid => pid === problem.id)) {
      const update = checkedProblems.filter(pid => pid !== problem.id);
      updateCheckedProblems(update);
    } else {
      updateCheckedProblems([ ...checkedProblems, problem.id ] )
    }
  }

  const isAuthenticated = auth0Client.isAuthenticated();
  const user = auth0Client.getProfile();

  if (!isAuthenticated) {
    return (
      <div
        className="pageWrapper"
      >
        Please log in in order to view your saved results.
      </div>

    )
  }

  if (!user) {
    return (
      <div
        className="pageWrapper"
      >
        <Loader
          active
          size="massive"
        />
      </div>
    )
  }

  if (!user.email_verified) {
    return (
      <div
        className="pageWrapper"
      >
        You must check your email and verify your account before you can view this page.
      </div>
    )
  }

  return (
    <div
      className="pageWrapper completedProblemsPage"
    >


        {/*<Button*/}
        {/*  color="red"*/}
        {/*  onClick={() => this.setState({ deleteModalOpen: true })}*/}
        {/*>*/}
        {/*  Delete Saved Problems*/}
        {/*</Button>*/}

        {/*<Button*/}
        {/*  onClick={() => props.AppState.doAnAsyncThing()}*/}
        {/*>*/}
        {/*  Global thing*/}
        {/*</Button>*/}


          <Query
            query={GET_PRACTICE_RESULTS_BY_USER_ID}
            variables={{ userId: user.sub }}
            fetchPolicy="network-only"
            pollInterval={10000}
          >
            {({ loading, error, data, refetch }) => {
              if (loading) return (
                <div
                  className="pageWrapper"
                >
                  <Loader active size="massive"/>
                </div>
              );
              if (error) return `Error! ${error}`;

              const completedProblems = JSON.parse(data.getPracticeResultsByUserId);
              const sortedCompleteProblems = sortCompletedProblems(completedProblems);

              if (!completedProblems.length) {
                return (
                  <div className="grokCard">
                    Sorry, no results were found.
                  </div>
                )
              }

              return (
                <Container>
                  <SubmitProblemsModal
                    disabled={checkedProblems.length === 0}
                    completedProblems={completedProblems}
                    checkedProblems={checkedProblems}
                    refetchProblems={refetch}
                  />
                  <h2>My Completed Problems</h2>


                  {
                    sortedCompleteProblems.map(submission => {
                      if (!submission.problems.length) {
                        return null;
                      }
                      return (
                        <Grid className="grokCard" key={submission.submissionId}>
                          <Grid.Row columns={12}>

                            <Grid.Column width={4}>
                              <h4>{submission.submissionName}</h4>
                            </Grid.Column>
                            {/*<Grid.Column width={4}>*/}
                            {/*  <div>{submission.studentFirstName} {submission.studentLastName}</div>*/}
                            {/*</Grid.Column>*/}

                            {/*<Grid.Column width={4}>*/}
                            {/*  <div>{submission.studentEmail}</div>*/}
                            {/*</Grid.Column>*/}

                          </Grid.Row>
                          <Grid.Row columns={5}>
                            <Grid.Column>
                              <strong>Submit</strong>
                            </Grid.Column>

                            <Grid.Column>
                              <strong>Type</strong>
                            </Grid.Column>

                            <Grid.Column>
                              <strong>Name</strong>
                            </Grid.Column>

                            <Grid.Column>
                              <strong>Date</strong>
                            </Grid.Column>

                            <Grid.Column>
                              <strong>Times Checked</strong>
                            </Grid.Column>
                          </Grid.Row>

                          {
                            submission.problems.map(problem => {
                              return (
                                <Grid.Row columns={5} key={problem.id}>
                                  <Grid.Column
                                  >
                                    {
                                      !problem.submission ? (
                                          <Checkbox
                                            checked={checkedProblems.some(pid => pid === problem.id)}
                                            onChange={() => addRemoveProblem(problem)}
                                          />
                                        ) :
                                        <span>Submitted</span>
                                    }

                                  </Grid.Column>

                                  {/*Problem Type*/}
                                  <Grid.Column
                                  >
                                    {problemTypes[problem.typeId].name || ''}
                                  </Grid.Column>

                                  {/*Problem Name*/}
                                  <Grid.Column
                                  >
                                    {problem.problem.name || ''}
                                  </Grid.Column>

                                  {/*Date completed*/}
                                  <Grid.Column
                                  >
                                    {moment(problem.timestamp).format('MMMM DD, YYYY') || ''}
                                  </Grid.Column>

                                  {/*Number of Times Checked*/}
                                  <Grid.Column
                                  >
                                    {problem.numberOfTimesChecked || ''}
                                  </Grid.Column>
                                </Grid.Row>
                              )
                            })
                          }
                        </Grid>
                      )
                    })
                  }

                </Container>
              );
            }}

          </Query>


      {/*<ConfirmModal*/}
      {/*  open={this.state.deleteModalOpen}*/}
      {/*  title="Delete Completed Problems"*/}
      {/*  confirmButtonLabel="Delete"*/}
      {/*  cancelButtonLabel="Cancel"*/}
      {/*  confirmText="Are you sure that you'd like to delete your completed problems?"*/}
      {/*  confirmButtonColor="red"*/}
      {/*  onConfirm={() => this.clearAllSavedProblems()}*/}
      {/*  onCancel={() => this.setState({ deleteModalOpen: false })}*/}
      {/*/>*/}

    </div>

  )


}
const CompletedProblemsWithState = withState(CompletedProblems);
export default CompletedProblemsWithState
// export default compose(
//   graphql(
//     GET_PRACTICE_RESULTS_BY_USER_ID,
//     {
//       options: {
//         fetchPolicy: 'network-only',
//         variables: {
//           userId: getUserIdFromLocalStorage()
//         }
//       }
//     },
//   ),
//   graphql(ANION_QUERY, { name: 'anions' }),
// )(CompletedProblemsWithState);


