export function toggleTheme() {
  this.setState(state => {
    return {
      theme:
        state.theme === 'darkMode'
          ? 'lightMode'
          : 'darkMode'
    };
  });
}

export function doAnAsyncThing() {
  // do a bunch of async stuff, call apis etc.
  // ...
  setTimeout(() => {
    console.log('hello after 5 seconds')
    this.setState( state => ({
      lastAsyncThingFinished: new Date()
    }))
  }, 1000);
}

export default [
  toggleTheme,
  doAnAsyncThing
]
