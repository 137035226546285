// window.location.pathname

let redirect = {
  callback: 'https://grokchemistry.com/callback',
  logout: 'https://grokchemistry.com',
};
if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_BUILD_ENV === 'prod') {
  redirect.callback = 'https://grokchemistry.com/callback';
  redirect.logout = 'https://grokchemistry.com';
} else if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_BUILD_ENV === 'dev') {
  redirect.callback = 'https://dev.grokchemistry.com/callback';
  redirect.logout = 'https://dev.grokchemistry.com';
} else if (process.env.NODE_ENV === 'development') {
  redirect.callback = 'http://localhost:3000/callback';
  redirect.logout = 'http://localhost:3000';
}

const configObject = {
  auth0Domain: 'grokchemistry.auth0.com',
  auth0ClientId: '7Srdrkn3slX160dSlsMuAZgwJ3xxaJEq',
  auth0Redirect: redirect.callback,
  GraphQLURI: process.env.NODE_ENV === 'development' ? 'http://localhost:4000/api' : 'https://grokchemistry.com/api',
  logoutURL: redirect.logout,
};

export default configObject;
