import React from "react";
import { Route } from "react-router-dom";
import Auth from '../../auth/Auth';

const PrivateRoute = ({ component: Component, path, ...rest }) => {

  const isAuthenticated = Auth.isAuthenticated();

  if (!isAuthenticated) {
    Auth.signIn();
  }

  const render = props => isAuthenticated === true ? <Component {...props} /> : null;

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
