export function sortCompletedProblems(completedProblems) {
  // Find all the submissions
  const submissions = {};
  completedProblems.forEach(problem => {
    // Found a new assignment
    if (problem.submission && !submissions[problem.submission.assignmentId]) {
      submissions[problem.submission.submissionId] = { ...problem.submission, problems: [] };
    }
  });

  // Now add all the submitted problems
  Object.keys(submissions).forEach(submissionId => {
    submissions[submissionId].problems = completedProblems.filter(prob => prob.submission && prob.submission.submissionId === submissionId);
  })


  // Convert to an array to make easier to loop
  const sortedSubmissions = [];
  Object.keys(submissions).forEach(submissionId => {
    sortedSubmissions.push(submissions[submissionId]);
  })

  sortedSubmissions.unshift({
    submissionId: null,
    submissionName: 'Not Yet Submitted',
    problems: completedProblems.filter(problem => !problem.submission),
    teacherEmail: null,
    timestamp: null,
    studentFirstName: null,
    studentLastName: null,
    studentEmail: null,
  });
  return sortedSubmissions;
}

export function extractStudentListFromSubmittedProblems(completedProblems) {
  const studentList = [];
  completedProblems.forEach(problem => {
    if (problem.submission && !studentList.some(student => (student.value === problem.submission.studentEmail) )) {
      studentList.push({
        key: problem.submission.studentEmail,
        value: problem.submission.studentEmail,
        text: `${problem.submission.studentLastName}, ${problem.submission.studentFirstName}`
      })
    }
  })
  return studentList;
}
