import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import auth0Client from '../../auth/Auth';
import { Loader } from "semantic-ui-react";

class Callback extends Component {
  async componentDidMount() {
    if (!auth0Client.isAuthenticated()) {
      try {
        await auth0Client.handleAuthentication();
      } catch(e) {
        console.log(e);
        auth0Client.signIn();
      }
    }
    // const user = auth0Client.getProfile();

    this.props.history.replace('/');
  }

  render() {
    return (
      <div
        className="pageWrapper"
      >
        <Loader
          active
          size="massive"
        />
      </div>

    );
  }
}

export default withRouter(Callback);
